import { Injectable, OnInit } from '@angular/core';
import {
  AuthChangeEvent,
  AuthSession,
  createClient,
  PostgrestResponse,
  PostgrestSingleResponse,
  Session,
  SupabaseClient,
  User,
} from '@supabase/supabase-js';
import { environment } from '@env/environment';
import { of } from 'rxjs';
import { DateTime } from 'luxon';
import { AreaMapper } from '@app/shared/SupabaseTypes/area-mapper';
import { IArea } from '@app/shared/SupabaseTypes/IArea';
import { IRestaurant } from '@app/shared/SupabaseTypes/IRestaurant';
import { RestaurantMapper } from '@app/shared/SupabaseTypes/restaurant-mapper';
import { ICustomer } from '@app/shared/SupabaseTypes/ICustomer';
import { CustomerMapper } from '@app/shared/SupabaseTypes/customer-mapper';
import { IOnboardingProcess } from './SupabaseTypes/IOnboardingProcess';
import { OnboardingProcessMapper } from './SupabaseTypes/onboarding-process-mapper';
import { IWidgetConfigurations } from './SupabaseTypes/IWidgetConfigurations';
import { UserMapper } from '@app/shared/SupabaseTypes/user-mapper';
import { IUser } from '@app/shared/SupabaseTypes/IUser';
import { ITenant } from '@app/shared/SupabaseTypes/ITenant';
import { TenantMapper } from '@app/shared/SupabaseTypes/tenant-mapper';
import { ILanguage } from '@app/shared/SupabaseTypes/ILanguage';
import { LanguageMapper } from '@app/shared/SupabaseTypes/language-mapper';
import { ITable } from '@app/shared/SupabaseTypes/ITable';
import { IOpeningHours } from '@app/shared/SupabaseTypes/IOpeningHours';
import * as Sentry from '@sentry/angular';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from '@app/shared/translation.service';
import { ISmsHistory } from '@app/shared/SupabaseTypes/ISmsHistory';
import { SmsHistoryMapper } from '@app/shared/SupabaseTypes/smshistory-mapper';
import { ITag } from './SupabaseTypes/ITag';
import { IReservationLength } from '@app/shared/SupabaseTypes/IReservationLengths';
import { ReservationLengthMapper } from '@app/shared/SupabaseTypes/ReservationLengthsMapper';

@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  private supabaseNativeClient: SupabaseClient;
  _session: AuthSession | null = null;
  tenant = null;

  private languageCache = new Map<string, ILanguage>();

  protected DAYS_AHEAD_TO_CACHE: number = 7; // Vurder å gjøre denne til en property på tenanten og at den kan kontrolleres av tenanten selv. Da kan man styre hvor mye cache/minne som brukes på enheten til kunden. Kan være greit hvis folk kjører på gammel hardware eller kjører tjenesten rett på kassaløsningen sin

  // angrer litt påp denne nå som den har vokst litt. Lag en service eller finn et bibliotek for dette?
  private localStorage: {
    areas: Map<number, IArea[]>; // areaId, IArea[]
    tenant: ITenant | null;
    restaurants: IRestaurant[] | null;
    tables: Map<string, ITable[]>;
    openingHours: Map<number, IOpeningHours[]>; // restaurantId -> openingHours
    reservationBlocks: Map<number, any[]>; // restaurantId -> openingHours
  };
  HOUR_12_TIME_FORMAT: boolean = false; // ikke gjør dette på denne måten, lag heller en ny appConstantsService som kan brukes. Er litt ryddigere...
  DATE_FORMAT_FOR_TENANT!: string;
  constructor(private toastr: ToastrService) {
    console.log('SUPABASE SERVICE CONSTRUCTOR RUNNING');
    // TODO Trekk ut localStorage i en annen service
    // Har denne for å redusere kall til supabase
    // Pass på å kun lagre ting som ikke endrer seg ofte
    this.localStorage = {
      tenant: null,
      restaurants: null,
      areas: new Map<number, IArea[]>(),
      tables: new Map<string, ITable[]>(),
      openingHours: new Map<number, IOpeningHours[]>(),
      reservationBlocks: new Map<number, any[]>(),
    };

    this.supabaseNativeClient = createClient(
      environment.supabaseUrl,
      environment.supabaseKey
    );
  }

  getSupabaseNativeClient() {
    return this.supabaseNativeClient;
  }

  private getSession2Promise: Promise<any> | null = null;

  async getSession2(): Promise<any> {
    if (this.getSession2Promise) {
      return this.getSession2Promise;
    }

    this.getSession2Promise = (async () => {
      if (this._session) {
        return this._session;
      }
      const { data } = await this.supabaseNativeClient.auth.getSession();
      if (data && data.session && data.session !== null) {
        this._session = data.session;
        return this._session;
      }
      return undefined;
    })().finally(() => {
      this.getSession2Promise = null;
    });

    return this.getSession2Promise;
  }

  authChanges(
    callback: (event: AuthChangeEvent, session: Session | null) => void
  ) {
    this.supabaseNativeClient.auth.getSession().then((x) => {
      console.debug('AuthChanges, session:', x.data.session, event);
    });
    return this.supabaseNativeClient.auth.onAuthStateChange(callback);
  }

  signIn(email: string) {
    return this.supabaseNativeClient.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: window.location.origin,
        data: {
          username: email,
        },
      },
    });
  }
  verifyTokenReceivedByEmail(email: string, tokenFromEmail: string) {
    return this.supabaseNativeClient.auth.verifyOtp({
      email,
      token: tokenFromEmail,
      type: 'email',
    });
  }

  signInWithPassword(email: string, password: string) {
    return this.supabaseNativeClient.auth.signInWithPassword({
      email,
      password,
    });
  }

  signOut() {
    this._session = null;
    return this.supabaseNativeClient.auth.signOut();
  }

  profile(user: User) {
    return this.supabaseNativeClient
      .from('users')
      .select(`name`)
      .eq('id', user.id)
      .single();
  }

  async updateCurrentUser(name: string, phone: string): Promise<any> {
    const userId = this._session?.user?.id;
    const { data, error } = await this.supabaseNativeClient
      .from('users')
      .update({ name, phone })
      .eq('id', userId);

    if (error) {
      console.error('Error updating user:', error);
      return null;
    }

    return data;
  }

  async setTenantData(
    name?: string,
    address?: string,
    timezone?: string
  ): Promise<any> {
    return await this.supabaseNativeClient
      .from('tenants')
      .update({
        name,
        address,
        timezone,
      })
      .eq('id', (await this.getTenant()).id)
      .single();
  }

  async setTenantDefaultLanguage(defaultLanguage: string): Promise<any> {
    await this.addLanguage(defaultLanguage); // Default language must be added to the tenant languages for stuff to work properly
    return this.supabaseNativeClient
      .from('tenants')
      .update({
        defaultLanguage,
      })
      .eq('id', (await this.getTenant()).id)
      .select()
      .single()
      .then((result) => {
        this.localStorage.tenant = new TenantMapper().toDomain(result.data);
        return result;
      });
  }

  async updateSmsSender(newSender: string, restaurantId: number): Promise<any> {
    return this.supabaseNativeClient
      .from('restaurant')
      .update({
        smsSenderName: newSender,
      })
      .eq('id', restaurantId)
      .single()
      .then((result) => {
        this.localStorage.restaurants = null;
        this.getRestaurants();
      });
  }

  private getTenantPromise: Promise<ITenant> | null = null;

  async getTenant(): Promise<ITenant> {
    if (this.getTenantPromise) {
      return this.getTenantPromise;
    }

    this.getTenantPromise = (async () => {
      if (this.localStorage.tenant) {
        return this.localStorage.tenant;
      }

      return this.supabaseNativeClient
        .from('tenants')
        .select()
        .single()
        .then((result) => {
          if (result.data !== null) {
            this.localStorage.tenant = new TenantMapper().toDomain(result.data);

            this.HOUR_12_TIME_FORMAT =
              this.localStorage.tenant.hourFormatForDateTime === 'hh:mm a';

            this.DATE_FORMAT_FOR_TENANT = this.localStorage.tenant.dateFormat;

            if (environment.production || environment.staging) {
              Sentry.setUser({
                'Tenant id': this.localStorage.tenant.id,
                'Tenant Owner Name': this.localStorage.tenant.name,
              });
            }

            return this.localStorage.tenant;
          } else {
            throw new Error('SupabaseService.getTenantData returns no data');
          }
        });
    })().finally(() => {
      this.getTenantPromise = null;
    });

    return this.getTenantPromise;
  }

  async getLanguages(): Promise<ILanguage[]> {
    const result = await this.supabaseNativeClient.from('languages').select();

    if (result.data !== null) {
      return result.data.map((r: ILanguage) =>
        new LanguageMapper().toDomain(r)
      );
    } else {
      throw new Error('SupabaseService.getLanguages returns no data');
    }
  }

  async getLanguageByCode(languageCode: string): Promise<ILanguage> {
    if (this.languageCache.has(languageCode)) {
      // @ts-ignore
      return this.languageCache.get(languageCode);
    }

    const result = await this.supabaseNativeClient
      .from('languages')
      .select()
      .eq('id', languageCode)
      .single();

    if (result.data !== null) {
      const language = new LanguageMapper().toDomain(result.data);
      this.languageCache.set(languageCode, language);
      // @ts-ignore
      return this.languageCache.get(languageCode);
    } else {
      throw new Error('SupabaseService.getLanguageByCode returns no data');
    }
  }

  async setTenantTimezone(ianaZone: string, tenantId: string): Promise<any> {
    await this.supabaseNativeClient
      .from('tenants')
      .update({ timezone: ianaZone })
      .eq('id', tenantId)
      .single();
  }

  async setTenantDateFormat(format: string, tenantId: string): Promise<any> {
    await this.supabaseNativeClient
      .from('tenants')
      .update({ dateFormat: format })
      .eq('id', tenantId)
      .single();
  }

  async setTenantTimeFormat(format: string, tenantId: string): Promise<any> {
    await this.supabaseNativeClient
      .from('tenants')
      .update({ hourFormat: format })
      .eq('id', tenantId)
      .single();
  }

  async getOnboardingProcess(): Promise<IOnboardingProcess | null> {
    let onboardingProcess = await this.supabaseNativeClient
      .from('onboarding_process')
      .select()
      .maybeSingle();

    console.debug('onboarding_process', onboardingProcess.data);

    if (onboardingProcess.data !== null) {
      return new OnboardingProcessMapper().toDomain(onboardingProcess.data);
    } else {
      return null;
    }
  }

  async upsertRestaurant(
    id: string | undefined,
    name: string,
    regulatoryMaxGuestCapacity: number,
    reservationIntervals: number,
    address?: string,
    city?: string,
    country?: string
  ): Promise<any> {
    console.debug('Upsert restaurant', id);
    const upsertObj = {
      id,
      name,
      regulatoryMaxGuestCapacity,
      reservationIntervals,
      smsSenderName: this.createSmsSenderName(name),
      tenantId: (await this.getTenant()).id,
    };

    if (address) {
      upsertObj['address'] = address;
    }
    if (city) {
      upsertObj['city'] = city;
    }
    if (country) {
      upsertObj['country'] = country;
    }
    const result = this.supabaseNativeClient
      .from('restaurant')
      .upsert(upsertObj)
      .select()
      .single();

    this.localStorage.restaurants = null;

    return result;
  }

  createSmsSenderName(name: string): string {
    // Step 1: Remove all characters that are not upper- or lower-case ASCII letters, digits, or spaces
    let cleanedName = name.replace(/[^a-zA-Z0-9 ]/g, '');

    // Step 2: Ensure the resulting string is not only numerals
    if (/^\d+$/.test(cleanedName)) {
      cleanedName = 'Resmium'; // Fallback to a default name if only numerals
    }

    // Step 3: Truncate the string to a maximum of 11 characters
    return cleanedName.substring(0, 11);
  }
  async upsertRestaurantLocation(
    id: string | undefined,
    address: string,
    city: string,
    country: string
  ): Promise<any> {
    console.debug('Upsert restaurant location', id);
    const result = this.supabaseNativeClient
      .from('restaurant')
      .update({
        address,
        city,
        country,
      })
      .eq('id', id)
      .eq('tenantId', (await this.getTenant()).id)
      .select()
      .single();

    this.localStorage.restaurants = null;

    return result;
  }

  async getRestaurants(): Promise<IRestaurant[]> {
    if (this.localStorage.restaurants) {
      return this.localStorage.restaurants;
    }

    return this.supabaseNativeClient
      .from('restaurant')
      .select()
      .then((newVar2) => {
        console.debug('restaurants all', newVar2.data);

        if (newVar2.data !== null) {
          this.localStorage.restaurants = newVar2.data.map((r: IRestaurant) =>
            new RestaurantMapper().toDomain(r)
          );

          return this.localStorage.restaurants;
        } else {
          throw new Error('SupabaseService.getRestaurant returns no data');
        }
      });
  }

  async createArea(
    restaurantId: number,
    id: number | undefined,
    nameTextId: number,
    bookingPriority: number,
    availableOnline: boolean
  ): Promise<any> {
    const result = await this.supabaseNativeClient
      .from('areas')
      .upsert({
        restaurantId,
        id,
        nameTextId,
        bookingPriority,
        availableOnline,
      })
      .select()
      .single();

    this.localStorage.areas.clear();

    const newArea = await this.getArea(restaurantId, result.data.id);
    return newArea;
  }

  async updateUsersProgressInOnboardingProcess(
    stepInOnboardingProcess: string
  ): Promise<any> {
    const tenantId = (await this.getTenant()).id;
    const existingOnboardingData = await this.getOnboardingProcess();

    if (
      existingOnboardingData &&
      !Object.keys(existingOnboardingData).find(
        (key) => key === stepInOnboardingProcess
      )
    ) {
      throw new Error(
        'Looks like you are trying to update a step that does not exist in the onboarding process. The step name must be added to the onboarding_process table in the database.'
      );
    }

    const id = existingOnboardingData?.id ?? undefined;

    return this.supabaseNativeClient
      .from('onboarding_process')
      .upsert({
        ...(id ? { id } : {}),
        tenantId,
        [stepInOnboardingProcess]: DateTime.now(),
      })
      .select()
      .single();
  }

  async upsertTable(
    areaId: number,
    id: number | undefined,
    name: string,
    capacityFrom: number,
    capacityTo: number,
    shape: string | undefined,
    location: string | undefined, // in area
    status: string | undefined,
    blockedUntil: string | undefined,
    bookingPriority: number
  ): Promise<any> {
    const result = this.supabaseNativeClient
      .from('tables')
      .upsert({
        areaId,
        id,
        name,
        capacityFrom,
        capacityTo,
        shape,
        location,
        status,
        blockedUntil,
        bookingPriority,
      })
      .select()
      .single();

    this.localStorage.tables.clear();

    return result;
  }

  async getTablesByAreaId(areaId: number): Promise<any> {
    let tables = await this.supabaseNativeClient
      .from('tables')
      .select()
      .is('deleted_at', null)
      .eq('areaId', areaId);
    return tables.data;
  }

  private getAllTablesPromise: Map<string, Promise<any>> = new Map();
  async getAllTables(
    restaurantId: number,
    areasInRestaurant: number[]
  ): Promise<any> {
    const cacheKey = restaurantId + '-' + areasInRestaurant?.join('_');

    if (this.localStorage.tables.has(cacheKey)) {
      return this.localStorage.tables.get(cacheKey);
    }

    if (this.getAllTablesPromise.has(cacheKey)) {
      return this.getAllTablesPromise.get(cacheKey)!;
    }

    const promise = Promise.resolve(
      // Fordi supabase returnerer PromiseLike
      this.supabaseNativeClient
        .from('tables')
        .select()
        .is('deleted_at', null)
        .in('areaId', areasInRestaurant)
    )
      .then((tables) => {
        this.localStorage.tables.set(cacheKey, tables.data ? tables.data : []);
        return tables.data;
      })
      .finally(() => {
        this.getAllTablesPromise.delete(cacheKey);
      });

    this.getAllTablesPromise.set(cacheKey, promise);

    return promise;
  }

  async deleteRestaurant(restaurantId: number): Promise<any> {
    return this.supabaseNativeClient
      .from('restaurant')
      .delete()
      .eq('id', restaurantId);
  }

  async deleteArea(areaId: number): Promise<any> {
    const result = await this.supabaseNativeClient
      .from('areas')
      .update({
        deleted_at: new Date(),
      })
      .eq('id', areaId)
      .select();

    if (result.data) {
      const idsToDelete: number[] = result.data.map(
        (area: any) => area.nameTextId
      );
      await this.getSupabaseNativeClient()
        .from('translations')
        .delete()
        .in('textId', idsToDelete);
    }

    console.log('deleted area', result.data);
    return result;
  }

  async deleteTable(tableId: number): Promise<any> {
    return this.supabaseNativeClient
      .from('tables')
      .update({
        deleted_at: new Date(),
      })
      .eq('id', tableId);
  }

  async getAvailableTables(
    restaurantId: number,
    bookingDate: Date,
    bookingStartTime: string,
    bookingEndTime: string,
    guestCount: number
  ): Promise<any> {
    if (!restaurantId || !bookingDate || !bookingStartTime || !bookingEndTime) {
      throw new Error('All parameters needs to be provided..');
    }

    console.debug('getAvailableTables', bookingStartTime, bookingEndTime);
    let xxx = await this.supabaseNativeClient.rpc(
      'get_available_tables_all_areas_authenticated',
      {
        input_restaurantid: restaurantId,
        input_starttime: DateTime.fromJSDate(bookingDate)
          .set({
            hour: +bookingStartTime.split(':')[0],
            minute: +bookingStartTime.split(':')[1],
            second: 0,
            millisecond: 0,
          })
          .toSQL(),
        input_endtime: DateTime.fromJSDate(bookingDate)
          .set({
            hour: +bookingEndTime.split(':')[0],
            minute: +bookingEndTime.split(':')[1],
            second: 0,
            millisecond: 0,
          })
          .toSQL(),
      }
    );
    if (xxx.error && xxx.error.message === 'TypeError: Failed to fetch') {
      this.toastr.error(
        'No internet connection - failed to fetch bookings - try to refresh the browser and check your internet connection'
      );
    }

    console.debug(
      'SupabaseService.getAvailableTables',
      restaurantId,
      bookingStartTime,
      bookingEndTime,
      xxx.data
    );

    return xxx.data;
  }

  private getAreasPromise: Map<number, Promise<IArea[]>> = new Map();

  async getAreas(restaurant: number): Promise<IArea[]> {
    if (this.getAreasPromise.has(restaurant)) {
      return this.getAreasPromise.get(restaurant)!;
    }

    this.getAreasPromise.set(
      restaurant,
      (async () => {
        const localStorageAreas = this.localStorage.areas.get(restaurant);
        if (localStorageAreas) {
          return localStorageAreas;
        }

        let res;
        if (restaurant) {
          res = await this.supabaseNativeClient
            .from('areasWithTranslationsInDefaultLanguage')
            .select()
            .is('deleted_at', null)
            .eq('restaurantId', restaurant);
        } else {
          console.error('Blir jeg noen gang kalt?');
          res = await this.supabaseNativeClient
            .from('areasWithTranslationsInDefaultLanguage')
            .select();
        }

        if (res && res.data) {
          const tmp = res.data?.map((r) => new AreaMapper().toDomain(r));
          this.localStorage.areas.set(restaurant, tmp);
          return tmp;
        } else {
          return [];
        }
      })().finally(() => {
        this.getAreasPromise.delete(restaurant);
      })
    );

    return this.getAreasPromise.get(restaurant)!;
  }

  async getArea(restaurantId: number, areaId: number): Promise<IArea> {
    const res = await this.supabaseNativeClient
      .from('areasWithTranslationsInDefaultLanguage')
      .select()
      .eq('id', areaId)
      .eq('restaurantId', restaurantId)
      .single();

    if (res.data) {
      return new AreaMapper().toDomain(res.data);
    } else {
      throw new Error(
        'Did not find the area for restaurant with id ' + restaurantId
      );
    }
  }

  buildCustomerQuery(
    restaurantId: number | null,
    fromDate: string,
    toDate: string,
    name: string,
    tenantId: string,
    tagsIds: number[]
  ) {
    let baseQuery =
      '*, b0:bookings!inner(startTime), b1:bookings!inner(startTime.max()), b2:bookings!inner(id.count(), guestCount.avg())';

    if (tagsIds.length > 0) {
      baseQuery += ', tags:customer_tags!inner(tagId)';
    }

    let query = this.supabaseNativeClient.from('customer').select(baseQuery);

    if (restaurantId) {
      query = query.eq('bookings.restaurantId', restaurantId);
    }

    if (fromDate !== '') {
      query = query.gte('b0.startTime', fromDate);
    }
    if (toDate !== '') {
      query = query.lte('b0.endTime', toDate);
    }

    if (name !== '') {
      query = query.ilike('full_name_search', `%${name}%`);
    }

    if (tagsIds.length > 0) {
      query = query.in('customer_tags.tagId', tagsIds);
    }

    query.eq('tenantId', tenantId);

    return query;
  }

  async getCustomersAsFile(
    restaurantId: number | null,
    fromDate: string,
    toDate: string,
    name: string,
    tagsIds: number[]
  ) {
    const tenantId = (await this.getTenant()).id;
    let query = this.buildCustomerQuery(
      restaurantId,
      fromDate,
      toDate,
      name,
      tenantId,
      tagsIds
    );

    let result = await query.csv();
    return result.data;
  }

  async getCustomers(
    start: number = 0,
    end: number = 10,
    restaurantId: number | null,
    fromDate: string,
    toDate: string,
    name: string,
    tagsIds: number[]
  ): Promise<ICustomer[]> {
    const tenantId = (await this.getTenant()).id;
    let query = this.buildCustomerQuery(
      restaurantId,
      fromDate,
      toDate,
      name,
      tenantId,
      tagsIds
    );
    query = query.range(start, end);

    const result = await query;

    if (result.data !== null) {
      return result.data.map((r: any) => new CustomerMapper().toDomain(r));
    } else {
      throw new Error('SupabaseService.getRestaurant returns no data');
    }
  }

  async getCustomerById(customerId: number): Promise<ICustomer> {
    const tenantId = (await this.getTenant()).id;
    let newVar2 = await this.supabaseNativeClient
      .from('customer')
      .select('*, customer_tags(tagId)')
      .eq('id', customerId)
      .eq('tenantId', tenantId)
      .single();

    if (newVar2.data !== null) {
      return new CustomerMapper().toDomain(newVar2.data);
    } else {
      throw new Error('SupabaseService.getCustomerById returns no data');
    }
  }

  async getCustomerTags(customerId: number): Promise<ITag[]> {
    const tenantId = (await this.getTenant()).id;
    let newVar2 = await this.supabaseNativeClient
      .from('customer_tags')
      .select('tags(id, name, description, icon, active)')
      .eq('customerId', customerId)
      .eq('tenantId', tenantId);

    let map = newVar2.data?.map((r) => {
      const tag = r.tags as unknown as ITag;
      tag.value = tag.name ? tag.name : 'N/A';
      return tag;
    });
    return map ? map : [];
  }

  async getCustomerByEmailOrPhone(
    email: string,
    phone: string
  ): Promise<ICustomer | null> {
    let newVar2 = await this.supabaseNativeClient
      .from('customer')
      .select()
      .or(`email.eq.${email},phoneNumber.eq.${phone}`)
      .maybeSingle();

    if (newVar2.data !== null) {
      return new CustomerMapper().toDomain(newVar2.data);
    } else {
      return null;
    }
  }

  async upsertCustomer(
    id: any,
    firstname: string,
    lastname: string,
    phoneNumber: string,
    email: string,
    staffNotes: string,
    gdprConsent: Date | null
  ): Promise<ICustomer> {
    phoneNumber = phoneNumber.replace(/\s/g, ''); // Remove all whitespace from phone number

    return this.supabaseNativeClient
      .from('customer')
      .upsert({
        id,
        firstname,
        lastname,
        phoneNumber,
        email,
        staffNotes,
        gdprConsent,
        tenantId: (await this.getTenant()).id,
      })
      .select()
      .single()
      .then((c: any) => new CustomerMapper().toDomain(c.data));
  }

  async upsertCustomerTag(tag: ITag, customerId: any): Promise<any> {
    const tenantId = (await this.getTenant()).id;
    return this.supabaseNativeClient
      .from('customer_tags')
      .upsert({
        customerId: customerId,
        tagId: tag.id,
        tenantId: tenantId,
      })
      .select();
  }

  async deleteCustomerTag(tag: ITag, customerId: any): Promise<any> {
    const tenantId = (await this.getTenant()).id;
    return this.supabaseNativeClient
      .from('customer_tags')
      .delete()
      .eq('customerId', customerId)
      .eq('tagId', tag.id)
      .eq('tenantId', tenantId);
  }

  async getCustomerCount(): Promise<any> {
    const { count, error } = await this.supabaseNativeClient
      .from('customer')
      .select('*', { count: 'exact', head: true });
    return count;
  }

  async deleteCustomer(customerId: number): Promise<any> {
    return this.supabaseNativeClient
      .from('customer')
      .delete()
      .eq('id', customerId);
  }

  async createCustomer(
    firstname: string,
    lastname: string,
    phoneNumber: string,
    email: string,
    gdprConsent: Date | null
  ): Promise<ICustomer> {
    if (!firstname || (!lastname && (!phoneNumber || !email))) {
      throw new Error(
        'First and last name, and either phone number or email must be supplied to create a new customer'
      );
    }

    phoneNumber = phoneNumber.replace(/\s/g, ''); // Remove all whitespace from phone number

    return await this.supabaseNativeClient
      .from('customer')
      .insert({
        firstname,
        lastname,
        phoneNumber,
        email,
        gdprConsent,
        tenantId: (await this.getTenant()).id,
      })
      .select()
      .single()
      .then((c: any) => new CustomerMapper().toDomain(c.data));
  }

  async verifySubscription(): Promise<any> {
    console.error('not implemented yet');
    return of(true).toPromise();
  }

  // Get the global widget configuration.
  // This should be the configuration where tenantId match, but restaurant ID is null.
  // If you want specify a unique theme for a restaurant, you can do that by creating a new widget configuration with the same tenantId,
  // but with the restaurantId set as well.
  async getGlobalWidgetConfigurations(
    tenantId: string
  ): Promise<IWidgetConfigurations | null> {
    const res = await this.supabaseNativeClient
      .from('widget_configurations')
      .select()
      .eq('tenantId', tenantId)
      .is('restaurantId', null)
      .single();
    console.log('res', res.data);
    if (res.data) {
      return res.data as IWidgetConfigurations;
    } else {
      console.warn('No widget configration found, returning empty array', res);
      return null;
    }
  }

  // Get wifget configuration by restaurantId.
  // tenantId is not needed as I guess row level security will ensure ony the correct tenant can access their own data.
  async getWidgetConfigurationsByRestaurantId(
    tenantId: string,
    restaurantId: number
  ): Promise<IWidgetConfigurations | null> {
    const res = await this.supabaseNativeClient
      .from('widget_configurations')
      .select()
      .eq('restaurantId', restaurantId);
    if (res.data && res.data.length > 0) {
      return res.data[0] as IWidgetConfigurations;
    } else {
      console.warn('No widget configration found, returning empty array');
      return null;
    }
  }

  async upsertWidgetConfigurations(
    widgetConfigurations: IWidgetConfigurations
  ): Promise<any> {
    return this.supabaseNativeClient
      .from('widget_configurations')
      .upsert({
        ...widgetConfigurations,
      })
      .select()
      .single();
  }

  async getUsers(): Promise<IUser[]> {
    let newVar2 = await this.supabaseNativeClient.from('users').select(`
        *,
        tenant_users (role)
      `);
    console.debug('users all', newVar2.data);

    if (newVar2.data !== null) {
      return newVar2.data.map((r: IUser) => new UserMapper().toDomain(r));
    } else {
      throw new Error('SupabaseService.getUsers returns no data');
    }
  }

  async getUser(userId: string): Promise<IUser> {
    let newVar2 = await this.supabaseNativeClient
      .from('users')
      .select(
        `
        *,
        tenant_users (role)
      `
      )
      .eq('id', userId)
      .single();
    console.debug('getUser() user data', newVar2.data);

    if (newVar2.data !== null) {
      return new UserMapper().toDomain(newVar2.data);
    } else {
      throw new Error('SupabaseService.getUser(userId) returns no data');
    }
  }

  async deleteUser(userId: string): Promise<IUser> {
    let newVar2 = await this.supabaseNativeClient
      .from('users')
      .update({ deleted_at: DateTime.now() })
      .eq('id', userId)
      .select()
      .single();
    console.debug('deleteUser() data', newVar2.data);

    if (newVar2.data !== null) {
      return new UserMapper().toDomain(newVar2.data);
    } else {
      throw new Error('SupabaseService.deleteUser(userId) returns no data');
    }
  }

  async reactivateUser(userId: string): Promise<IUser> {
    let newVar2 = await this.supabaseNativeClient
      .from('users')
      .update({ deleted_at: null })
      .eq('id', userId)
      .select()
      .single();
    console.debug('reactivateUser() data', newVar2.data);

    if (newVar2.data !== null) {
      return new UserMapper().toDomain(newVar2.data);
    } else {
      throw new Error('SupabaseService.reactivateUser(userId) returns no data');
    }
  }

  async createUser(
    email: string,
    password: string,
    name: string,
    restaurantIds: number[],
    role: string
  ): Promise<boolean> {
    const { data, error } = await this.supabaseNativeClient.functions.invoke(
      'create-user',
      {
        body: {
          email,
          password,
          name,
          restaurantIds,
          role,
        },
      }
    );

    console.debug('users all', data);

    if (data !== null) {
      return true;
    } else {
      throw new Error('SupabaseService.createUser returns no data');
    }
  }

  async updatePassword(userId: string, password: string): Promise<boolean> {
    const { data, error } = await this.supabaseNativeClient.functions.invoke(
      'set-new-password',
      {
        body: {
          userId,
          password,
        },
      }
    );

    console.debug('users all', data);

    if (data !== null) {
      return true;
    } else {
      throw new Error('SupabaseService.updatePassword returns no data');
    }
  }

  async getTenantLanguages(): Promise<ILanguage[]> {
    const result = await this.supabaseNativeClient.from('tenant_languages')
      .select(`
      *,
      languages (
        id, value
      )
      `);
    if (result.data !== null) {
      return result.data.map((r: any) =>
        new LanguageMapper().toDomain(r.languages)
      );
    } else {
      throw new Error('SupabaseService.getTenantLanguages returns no data');
    }
  }

  async addLanguage(language: string) {
    const insert = {
      languageId: language,
      tenantId: (await this.getTenant()).id,
    };
    await this.supabaseNativeClient
      .from('tenant_languages')
      .upsert(insert, { ignoreDuplicates: false })
      .select();
  }

  async removeLanguage(languageId: string) {
    await this.supabaseNativeClient
      .from('tenant_languages')
      .delete()
      .eq('languageId', languageId)
      .select();
  }

  async getCurrentUser(): Promise<IUser | undefined> {
    const userId = this._session?.user?.id;
    if (!userId) {
      throw new Error('Could not fetch current user. User is not logged in?');
    }

    try {
      return this.getUser(userId);
    } catch (e) {
      console.error(
        'Could not fetch current user. User is not logged in?. Redirecting to login. The userId from session was:',
        userId
      );
      this.signOut();
      return undefined;
    }
  }

  async blockTable(selectedTableId: number, duration: Date | null) {
    let newVar = await this.supabaseNativeClient
      .from('tables')
      .update({
        blockedUntil: duration ? DateTime.fromJSDate(duration).toSQL() : null,
      })
      .eq('id', selectedTableId)
      .single();

    this.localStorage.tables.clear();

    return newVar.data;
  }

  async createOnlineReservationBlock(
    name: string,
    restaurantId: number,
    activeFromDateJS: Date,
    activeToDateJS: Date,
    selectedTableIds: number[],
    selectedAreaIds: number[]
  ) {
    const tenantData = await this.getTenant();
    const { data, error } = await this.supabaseNativeClient
      .from('reservation_blocks')
      .insert({
        name,
        restaurantId,
        activeFromDateTime: activeFromDateJS,
        activeToDateTime: activeToDateJS,
        tables: selectedTableIds,
        areas: selectedAreaIds,
        tenantId: tenantData.id,
      })
      .select();

    if (data) {
      this.localStorage.reservationBlocks.clear();
      return true;
    } else {
      return false;
    }
  }

  async deleteReservationBlock(reservationBlockId: number) {
    this.localStorage.reservationBlocks.clear(); // litt crude, men tar ikke så lang tid å laste alle på nytt
    const { data, error } = await this.supabaseNativeClient
      .from('reservation_blocks')
      .delete()
      .eq('id', reservationBlockId);

    if (data) {
      return true;
    } else {
      return false;
    }
  }

  async getReservationBlocks(selectedRestaurantId: number): Promise<any[]> {
    if (!selectedRestaurantId) {
      return [];
    }

    if (this.localStorage.reservationBlocks.has(selectedRestaurantId)) {
      return this.localStorage.reservationBlocks.get(selectedRestaurantId)!;
    }

    const reservationBlockQuery = this.supabaseNativeClient
      .from('reservation_blocks')
      .select()
      .eq('restaurantId', selectedRestaurantId);

    return Promise.all([
      reservationBlockQuery,
      this.getAreas(selectedRestaurantId),
    ])
      .then((values) => {
        const newVar = values[0];
        const allAreas = values[1];

        if (newVar.data) {
          let rb = newVar.data;

          const areaIds = allAreas.map((area: IArea) => area.id);

          return this.getAllTables(selectedRestaurantId, areaIds)
            .then((allTables) => {
              rb = rb.map((block: any) => {
                block.activeFromDateTime = DateTime.fromISO(
                  block.activeFromDateTime
                );
                block.activeToDateTime = DateTime.fromISO(
                  block.activeToDateTime
                );
                block.tables = block.tables.map(
                  (t: number) =>
                    allTables.find((table: any) => table.id === t)?.name
                );
                block.areas = block.areas.map(
                  (a: number) => allAreas.find((area) => area.id === a)?.name
                );

                return block;
              });

              this.localStorage.reservationBlocks.set(selectedRestaurantId, rb);
              return rb;
            })
            .catch((err) => {
              console.error('Error getting tables for reservation blocks', err);
              return [];
            });
        } else {
          return [];
        }
      })
      .catch((e) => {
        console.error('Error getting reservation blocks', e);
        return [];
      });
  }

  async getSmsHistory(
    start: number = 0,
    end: number = 10,
    restaurantId: number | null,
    fromDate: string,
    toDate: string,
    name: string
  ): Promise<ISmsHistory[]> {
    let query = this.buildSmsQuery(restaurantId, fromDate, toDate, name);
    query = query.range(start, end);

    const result = await query;

    if (result.data !== null) {
      return result.data.map((r: any) => new SmsHistoryMapper().toDomain(r));
    } else {
      throw new Error('SupabaseService.getSmsHistory returns no data');
    }
  }

  buildSmsQuery(
    restaurantId: number | null,
    fromDate: string,
    toDate: string,
    name: string
  ) {
    let query = this.supabaseNativeClient
      .from('sms_history')
      .select('*, customer(*)');

    if (restaurantId) {
      query = query.eq('restaurantId', restaurantId);
    }

    if (fromDate !== '') {
      query = query.gte('created_at', fromDate);
    }
    if (toDate !== '') {
      query = query.lte('created_at', toDate);
    }

    if (name !== '') {
      query = query.ilike('text', `%${name}%`);
    }

    return query;
  }

  async getSmsPrices(): Promise<any> {
    const tenantId = (await this.getTenant()).id;
    const { data, error } = await this.supabaseNativeClient.functions.invoke(
      'get-sms-prices',
      {
        body: {
          tenantId,
        },
      }
    );

    console.debug('Sms prices response', data);

    return data;
  }

  async getSmsPrepaymentUrl(): Promise<string> {
    let environment = '';
    if (window.location.hostname.includes('localhost')) {
      environment = 'localhost';
    } else if (window.location.hostname.includes('staging')) {
      environment = 'staging';
    } else {
      environment = 'production';
    }
    const { data, error } = await this.supabaseNativeClient.functions.invoke(
      'sms-prepayment-and-setup',
      {
        body: {
          environment: environment,
        },
      }
    );

    console.debug('Sms prepayment url response', data);

    return data.redirectUrl;
  }

  private reservationLengthsCache = new Map<number, IReservationLength[]>();

  async upsertReservationLengths(reservationLengths: any[]) {
    this.reservationLengthsCache.clear();
    const { data, error } = await this.supabaseNativeClient
      .from('restaurant_reservation_length')
      .upsert(reservationLengths, {
        onConflict: 'tenantId, restaurantId, guests',
      });

    if (error) {
      console.error('Error upserting reservation lengths:', error);
    }
  }

  async deleteAllReservationLengths(restaurantId: number) {
    this.reservationLengthsCache.clear();
    const { data, error } = await this.supabaseNativeClient
      .from('restaurant_reservation_length')
      .delete()
      .eq('tenantId', (await this.getTenant()).id)
      .eq('restaurantId', restaurantId);

    if (error) {
      console.error('Error deleting reservation lengths:', error);
    }
  }

  async getReservationLengths(
    restaurantId: number
  ): Promise<IReservationLength[]> {
    if (this.reservationLengthsCache.has(restaurantId)) {
      return this.reservationLengthsCache.get(restaurantId)!;
    }

    const tenantId = (await this.getTenant()).id;
    const { data, error } = await this.supabaseNativeClient
      .from('restaurant_reservation_length')
      .select()
      .eq('restaurantId', restaurantId)
      .eq('tenantId', tenantId);

    if (error) {
      console.error('Error getting reservation lengths:', error);
      return [];
    }

    const retval = data.map((raw: any) =>
      new ReservationLengthMapper().toDomain(raw)
    );
    this.reservationLengthsCache.set(restaurantId, retval);
    return retval;
  }
}
