import { Mapper } from '@app/shared/SupabaseTypes/mapper';
import { DateTime } from 'luxon';
import { ICustomer } from '@app/shared/SupabaseTypes/ICustomer';

export class CustomerMapper implements Mapper<ICustomer> {
  toDomain(raw: any): ICustomer {
    if (!raw) {
      throw new Error(
        'CustomerMapper: toDomain: raw is null. You must supply data to the mapper.'
      );
    }
    const tmp = JSON.parse(JSON.stringify(raw));
    tmp.created_at = DateTime.fromISO(tmp.created_at).toUTC(0, {
      keepLocalTime: true,
    }); // litt hacky her, men jeg ignorerer foreløpig bare tidssoner

    if (tmp.b1 && tmp.b1.length > 0) {
      tmp.lastVisit = tmp.b1[0].max;
    }

    if (tmp.b2 && tmp.b2.length > 0) {
      tmp.reservations = tmp.b2[0].count;
      tmp.averageGuestCount = parseFloat(tmp.b2[0].avg.toFixed(2));
    }

    tmp.bookings = undefined;

    if (tmp.tags && tmp.tags.length > 0) {
      tmp.tags = tmp.tags.map((t: any) => t.id);
    }

    return tmp;
  }

  toPersistence(concrete: ICustomer): any {}
}
