import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ReservationLinkService {
  constructor() {}

  generatedBookingLink(
    tenantId: string,
    restaurantId: number,
    channel?: string
  ): string {
    let baseUrl = '';
    if (window.origin.indexOf('staging') > -1) {
      baseUrl = 'https://staging-book.resmium.com/booking';
    } else if (window.origin.indexOf('localhost') > -1) {
      baseUrl = 'http://localhost:5200/booking';
    } else {
      baseUrl = 'https://book.resmium.com/booking';
    }
    const url =
      baseUrl + '?tenantId=' + tenantId + '&restaurantId=' + restaurantId;

    if (channel) {
      return url + '&channel=' + channel;
    }

    return url;
  }
}
