import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SupabaseService } from '@app/shared/supabase.service';
import { environment } from '@env/environment';
import { ConfettiService } from '@app/shared/confetti.service';
import { ReservationLinkService } from '@app/widget/reservation-link.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { IRestaurant } from '@app/shared/SupabaseTypes/IRestaurant';

@Component({
  selector: 'app-onboarding-success',
  templateUrl: './onboarding-success.component.html',
  styleUrls: ['./onboarding-success.component.scss'],
})
export class OnboardingSuccessComponent implements OnInit, AfterViewInit {
  isLoading: boolean = false;
  restaurants: IRestaurant[] = [];
  link: string | undefined;
  constructor(
    private supabase: SupabaseService,
    private router: Router,
    private confetti: ConfettiService,
    private reservationLinkServiceService: ReservationLinkService,
    private clipboard: Clipboard,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    const [updateProgress, restaurants, tenant] = await Promise.all([
      this.supabase.updateUsersProgressInOnboardingProcess(
        'onboardingCompleted'
      ),
      this.supabase.getRestaurants(),
      this.supabase.getTenant(),
    ]);

    this.restaurants = restaurants;
    let iTenant = tenant;

    this.link = this.reservationLinkServiceService.generatedBookingLink(
      iTenant.id,
      this.restaurants[0].id,
      'restaurant-website'
    );
  }

  ngAfterViewInit(): void {
    this.confetti.shoot();
  }

  // Currently just makes the onboarding completed
  async createBooking() {
    this.router.navigate(['../overview']);
  }

  async copyToClipboardRestaurantLink() {
    if (!this.link) {
      return;
    }

    this.clipboard.copy(this.link);
    this.toastr.info(this.translateService.instant('general.copied'));
  }
}
