import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { AuthenticationService } from '@app/auth';
import { SubscriptionService } from '@app/shared/subscription.service';
import { environment } from '@env/environment';
import { ISubscription } from '@app/shared/SupabaseTypes/ISubscription';
import { SupabaseService } from '@app/shared/supabase.service';
import { IUser } from '@app/shared/SupabaseTypes/IUser';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  username!: string;
  appVersion: string = '';
  showMasterMenuInTopBar: boolean = false;
  drawer: string = '';
  currentUser: IUser | undefined;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private subscriptionService: SubscriptionService,
    private supabase: SupabaseService
  ) {
    this.router.events.subscribe((val) => {
      const urlsWhereMasterMenuShouldBeShown = [
        '/overview',
        '/timeline',
        '/map',
        '/filtered-list',
      ];
      if (urlsWhereMasterMenuShouldBeShown.includes(location.pathname)) {
        this.showMasterMenuInTopBar = true;
      } else {
        this.showMasterMenuInTopBar = false;
      }

      this.drawer = '';
    });
  }

  async ngOnInit() {
    this.appVersion = environment.version;
    this.username = await this.authenticationService.username();
    this.subscriptionService.getSubscription().then((res: ISubscription) => {
      console.log('Subscription is', res);
      console.log('Subscription res.created_at', res.created_at);
      console.log(
        "DateTime.fromISO(res.created_at).diffNow('days').days",
        DateTime.fromISO(res.created_at).diffNow('days').days
      );
      if (
        res.planActive === false &&
        DateTime.fromISO(res.created_at).diffNow('days').days < -14
      ) {
        this.router.navigate(['/onboarding/subscribe']);
      }
    });

    this.supabase.getCurrentUser().then((user) => (this.currentUser = user));
  }

  logout() {
    this.authenticationService.logout().subscribe(() => {
      localStorage.clear();
      this.router
        .navigate(['/login'], { replaceUrl: true })
        .then((r) => window.location.reload());
    });
  }

  hasAccess(): boolean {
    if (
      this.currentUser &&
      (this.currentUser.role === 'OWNER' ||
        this.currentUser.role === 'RESTAURANT_MANAGER')
    ) {
      return true;
    }
    return false;
  }
}
