<div
  class="flex flex-col justify-center items-center text-center"
  style="min-height: 100vh"
>
  <div class="max-w-3xl">
    <h1 translate class="heading1">onboarding.successTitle</h1>
    <h2 translate class="heading2">onboarding.successSubtitle</h2>

    <div class="flex flex-col gap-y-5">
      <p translate class="info">onboarding.whatsNextDescription</p>
    </div>

    <div *ngIf="restaurants.length > 0" class="m-12">
      <h2>{{ restaurants[0].name }}</h2>

      <p translate>widgetlink.restaurantWebsiteDesc</p>
      <div class="flex flex-row gap-3 items-center">
        <kbd class="kbd mt-4">
          <a [href]="link" target="_blank">{{ link }}</a>
        </kbd>
        <div (click)="copyToClipboardRestaurantLink()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
            />
          </svg>
        </div>
      </div>
    </div>

    <a class="btn btn-secondary mt-7" [href]="'../../../'" translate>
      <span *ngIf="!isLoading" translate>onboarding.successButton</span>
      <app-loader
        [isLoading]="isLoading"
        *ngIf="isLoading"
        class="inline-loader"
      ></app-loader>
    </a>
  </div>
</div>
